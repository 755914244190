/*
 * This allows to keep the scroll position on navigate with turbo.
 * This only makes sense if the page is the same page (e.g. if a form is
 * submitted.)
 * To use this simply add `data-turbo-keep-scroll` to any link, button or form,
 * etc. that leads to a new page load. In order to identify the scroll
 * container it is necessary to add a unique HTML id to it and reference that.
 * If the element is not found the HTML element is used. This means you can
 * also hand in `true` to scroll the HTML element.
 * ## Example
 *
 * ```
 * <div id="content">
 *   <!-- ... -->
 *   <a href="#" data-turbo-keep-scroll="content">...</a>
 * </div>
 * ```
 */
import ReactRailsUJS from "react_ujs";

let Scroll = {};

["turbo:click", "turbo:submit-start"].forEach((eventName) => {
  ReactRailsUJS.handleEvent(eventName, ({ target }) => {
    if (!target.dataset.turboKeepScroll) return;

    Scroll.id = target.dataset.turboKeepScroll;
    Scroll.top = (
      document.querySelector(`#${Scroll.id}`) || document.documentElement
    ).scrollTop;
  });
});

ReactRailsUJS.handleEvent("turbo:load", () => {
  if (Scroll.id && Scroll.top) {
    (
      document.querySelector(`#${Scroll.id}`) || document.documentElement
    )?.scrollTo(0, Scroll.top);
  }

  Scroll = {};
});
